* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

@font-face {
  font-family: silka;
  src: url(/fonts/silka-regular-webfont.woff);
  font-display: swap;
}

@font-face {
  font-family: silka;
  font-weight: 700;
  src: url("/fonts/silka-semibold.otf") format("opentype");
  font-display: swap;
}

@font-face {
  font-family: silka;
  font-weight: 900;
  src: url("/fonts/silka-bold.otf") format("opentype");
  font-display: swap;
}

html,
body {
  max-width: 100vw;
  color: #1f3a45;
  font-family: 'silka', sans-serif;
  font-size: 18px;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

main {
  overflow-x: hidden;
}

li.ant-menu-submenu-horizontal:after,
li.ant-menu-item:after {
  border-bottom-width: 0px !important;
}

.ant-menu-horizontal .ant-menu-submenu {
  padding-inline: 10px !important;
}

.ant-menu-item a:hover {
  text-decoration: none;
}

.ant-menu,
.ant-menu-title-content {
  color: #020849
}

.ant-menu-horizontal li .ant-menu-title-content {
  padding: 8px 12px;
}

.ant-menu-submenu-inline .ant-menu-item-active {
  background-color: #e8e8e8 !important;
}

.ant-menu-horizontal li:hover .ant-menu-title-content {
  background: #0000000a;
  border-radius: 10px;
}

.ant-menu-submenu-popup {
  z-index: 9999999;
}

h1,
h2,
h3,
h4,
h5,
h6,
.title {
  color: #1f3a45;
  font-weight: 900;
}

a {
  text-decoration: none;
  color: #1724aa;
  cursor: pointer;
}

.ant-checkbox {
  border-radius: 50%;
  overflow: hidden;
  transform: scale(1.7);
}

a:hover {
  text-decoration: underline;
}

.para_break {
  display: block;
  height: 8px;
}

.ant-slider-rail {
  background-color: #0000003b !important;
}

.ant-btn-primary {
  box-shadow: none;
}

.ant-btn {
  padding: 18px 20px;
  font-weight: bold;
}

.ant-btn.ant-btn-lg {
  height: 48px;
  padding: 10px 20px;
}

.ant-btn-link {
  font-weight: 400;
  font-size: 16px;
}

.ant-tag,
.ant-alert {
  border: none
}

.ant-tag.ant-tag-green {
  background: #e6ffcc;
}

.ant-modal-title {
  padding-right: 20px;
}

.ant-select-item-option-selected {
  background-color: #f2f2f2 !important;
}

.selectCustom .ant-select-selector {
  height: 44px !important;
}

.ant-carousel .slick-next::after,
.ant-carousel .slick-prev::after {
  width: 18px;
  height: 18px;
}

@media (max-width: 1000px) {
  .ant-btn {
    padding: 4px 10px;
  }
}

@media (max-width: 400px) {
  .selectCustom .ant-select-selector {
    height: 56px !important;
  }
}
.productScreen {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 12px;
    border-radius: 10px;
    background-color: white;
    width: fit-content;
    margin: auto;
    margin-top: 24px;
}
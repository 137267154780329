.MarketingBanner_wrapper {
  background-color: #4a2d88;
  color: white;
  padding: 4px 12px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 10001;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
  font-size: 17px;
}

.tag {
  border: none;
  font-weight: bold;
}

@media screen and (max-width: 560px) {

  .MarketingBanner_wrapper {
    flex-wrap: wrap;
    height: 88px;
    font-size: 15px;
  }

  .cta {
    height: 26px;
    padding: 2px 20px;
  }

}
@media screen and (max-width: 460px) {
  .MarketingBanner_wrapper {
    height: 98px;
  }
}
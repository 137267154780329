.processWrapper {
    display: flex;
    margin-top: 24px;

    .processBuddies,
    .processCompetition {
        flex-basis: 50%;
        margin: 6px;
    }

    h4 {
        margin-bottom: 10px;
    }

    ul {
        padding-inline-start: 24px;
        list-style: none;


        // &.redList li:before {
        //     content: "❌";
        //     display: inline-block;
        //     margin-right: 10px;
        // }

        &.greenList li:before {
            content: "✅";
            display: inline-block;
            margin-right: 10px;
            font-size: 22px;
        }
    }
}

.greenTitle {
    background: #00c41138;
    padding: 8px 22px;
    border-radius: 6px;
}

.redTitle {
    background: #ec000138;
    padding: 8px 22px;
    border-radius: 6px;
}

.redIcon {
    font-size: 22px;
    margin-right: 10px
}

@media (max-width: 650px) {
    .processWrapper {
        flex-wrap: wrap-reverse;

        .processBuddies,
        .processCompetition {
            flex-basis: 100%;
            margin: 6px;
        }
    }
}
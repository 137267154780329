.text {
    background: linear-gradient(transparent 32%, #ede0f6 25%);
    padding-left: 4px;
    margin-left: -4px;
    padding-right: 4px;
    margin-right: -4px;

    &.revert {
        background: linear-gradient(transparent 32%, #81c6ff6e 25%)
    }
}
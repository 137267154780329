
.Slack_alias {
  background-color: #e8f5fa;
  color: #1264a3;
  padding: 2px 3px;
  font-weight: 300 !important;
}

.Slack_preview_wrapper {
  display: flex;
  justify-content: center;
  max-width: 100%;
  .Slack_preview_sidebar {
    background: #411246;
    width: 120px;
    min-height: 400px;
    min-width: 80px;
  }
  .Slack_preview_main {
    background: white;
    max-width: calc(100% - 140px);
  }
  .Slack_preview_inner {
    background-color: white;
    padding: 26px;
    border-radius: 12px;
    display: flex;
    width: 550px;
    max-width: calc(100% - 40px);
    margin: auto;

    .Preview_reactions {
      max-width: 100%;
    }

    .Slack_preview_name_wrapper {
      display: flex;
      align-items: center;
    }
    .Slack_preview_picture {
      width: 40px;
      height: 40px;
      border-radius: 4px;
    }
    .Slack_preview_picture_wrapper {
      margin-right: 12px;
    }
    .Slack_preview_name {
      font-weight: bold;
      margin-right: 8px;
    }
    .Slack_preview_app {
      margin-right: 8px;
      background-color: #e2e2e2;
      color: #777777;
      font-size: 11px;
      font-weight: bold;
      border-radius: 2px;
      padding: 2px 2px;
    }
    .Slack_preview_time {
      color: #777777;
      font-size: 13px;
    }
    .Slack_preview_content {
      margin-top: 4px;
      font-weight: 400;
      span,
      div {
        font-weight: 400;
      }
    }
    .Slack_preview_content {
      .Slack_preview_v2_name {
        border-bottom: 1px solid #d1d1d1;
        display: flex;
        margin-bottom: 16px;
        padding-bottom: 14px;

        &.noBottom {
          border-bottom: none;
        }

        .Slack_preview_v2_name_left {
          flex-grow: 1;
        }
        .Slack_preview_v2_name_right {
          img {
            border-radius: 12px;
            width: 90px;
            margin-left: 6px;
          }
        }
      }
      .Slack_preview_v2_title {
        font-size: 21px;
        font-weight: 700;
      }
      .Slack_preview_v2_subtitle {
        color: grey;
        font-size: 13px;
        margin-bottom: 20px;
        margin-top: 12px;
        font-weight: 300;
      }
      .Slack_preview_v2_type {
        font-weight: 300;
        text-transform: capitalize;
      }
      .Slack_preview_v2_date {
        font-weight: 300;
        font-style: italic;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .Slack_preview_wrapper {
    .Slack_preview_sidebar {
      width: 80px;
    }
  }
}

@media screen and (max-width: 600px) {
  .Slack_preview_wrapper {
    .Slack_preview_inner {
      padding: 12px;
      margin: unset;
    }
  }
}
